import React from "react"
import PropTypes from "prop-types"
import {
  ExampleScreenshot,
  ExampleGridItem,
  ExampleHoverBackground,
  ExampleArrow,
  ExampleScreenshotWrapper,
} from "@components/resources/example-grid"

import Heading from "@components/elements/heading"
import Anchor from "@components/elements/anchor"
import Grid from "@components/elements/grid"
import Flex from "@components/elements/flex"
import Div from "@components/elements/div"
import Image from "@components/image"

export const ResourceGrid = ({
  title,
  suffix,
  shadow,
  kpi,
  examples,
  hideHeader,
  related,
  parentSlug,
}) => {
  const header = kpi ? `${title} Metrics & KPIs` : `${title} Dashboard Examples`
  const location = kpi ? `kpi-examples` : `dashboard-examples`

  return (
    <Flex gap="2rem" padding="3rem" borderRadius="1rem" background="#FAFAFF">
      {!hideHeader && <Heading as={"h2"}>{header}</Heading>}
      <Grid
        as="ul"
        gap="2rem"
        gapMd="1rem"
        margin="0"
        columns="repeat(3,1fr)"
        columnsMd="1fr 1fr"
        columnsSm="1fr"
      >
        {examples.map((example, i) => {
          // If a node is present, update the reference
          if (example.node) {
            example = example.node
          }

          const name = example.name ?? example.title
          const altTag = kpi
            ? `${title} KPI Examples - ${name} Metric`
            : `${title} Dashboard Examples - ${name} Dashboard`
          const imageAlt = suffix ? `${name} ${suffix}` : altTag
          var slug = example.category?.slug
            ? `${example.category.slug}/${example.slug}`
            : example.slug

          if (related) {
            slug = `/resources/${location}/${slug}`
          } else if (!kpi) {
            slug = example.slug
          }

          if (parentSlug) {
            slug = `/resources/${location}/${parentSlug}/${example.slug}`
          }
          return (
            example.status !== "draft" && (
              <Div as="li" margin="0" key={`kpi-${example.id}-${i}`}>
                <ExampleGridItem>
                  <ExampleScreenshotWrapper to={slug}>
                    <ExampleScreenshot kpi shadow={shadow}>
                      {example.image && (
                        <Image
                          file={example.image}
                          alt={imageAlt}
                          radius={0.75}
                          showOverflow
                        />
                      )}
                      <ExampleHoverBackground radius={"0.5rem 0.5rem 0 0"}>
                        <ExampleArrow />
                      </ExampleHoverBackground>
                    </ExampleScreenshot>
                  </ExampleScreenshotWrapper>
                  <Div padding="1.5rem">
                    <Heading letterSpacing="0" as="h3" fontSize="1.1rem">
                      <Anchor arrow color="inherit" to={slug}>
                        {name}
                        {suffix && ` ${suffix}`}
                      </Anchor>
                    </Heading>
                  </Div>
                </ExampleGridItem>
              </Div>
            )
          )
        })}
      </Grid>
    </Flex>
  )
}

ResourceGrid.propTypes = {
  title: PropTypes.string.isRequired,
  suffix: PropTypes.string,
  shadow: PropTypes.bool,
  kpi: PropTypes.bool,
  examples: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideHeader: PropTypes.bool,
  related: PropTypes.bool,
  parentSlug: PropTypes.string,
}

ResourceGrid.defaultProps = {
  shadow: true,
}
