import React from "react"
import Flex from "@components/elements/flex"
import Div from "@components/elements/div"
import Container from "@components/layout/container"
import Button from "@components/elements/button"
import Heading from "@components/elements/heading"
import styled, { useTheme } from "styled-components"
import { LowGradient } from "@components/common/gradient"
import Paragraph from "@components/elements/paragraph"
import { graphql, useStaticQuery } from "gatsby"
import Image from "@components/image"
import Anchor from "@components/elements/anchor"
import media from "../../../styles/media"

const FlexContainer = styled(Flex)`
  ${media.sm`
    .klipfolio-image-wrapper {
      width: 80px;
      height: 80px;
    }
  `}
`

export const NeutralCTA = () => {
  const { color, gradient: themeGradient } = useTheme()
  const data = useStaticQuery(
    graphql`
      query NeutralCTAQuery {
        gradient: directusFiles(
          directus_id: { eq: "56ded7c2-7a1c-4c29-9a69-5ce23a09f1af" }
        ) {
          id
          cdn
          title
        }
        pmLogo: directusFiles(
          directus_id: { eq: "986f308b-9a46-4961-966f-b14baa2969df" }
        ) {
          cdn
          id
          placeholder
          title
        }
        klipsLogo: directusFiles(
          directus_id: { eq: "085ae229-b751-40d5-be58-2e2e6be8a3eb" }
        ) {
          cdn
          id
          placeholder
          title
        }
      }
    `
  )

  return (
    <Div position="relative" background={color.indigo700} overflow="hidden">
      <LowGradient
        src={data.gradient.cdn}
        loading="lazy"
        style={{
          transform: "translate3d(-50%, -20%, 0) scaleX(-1)",
          zIndex: "0",
        }}
      />

      <Container style={{ zIndex: "1", position: "relative" }}>
        <Flex
          center
          alignItems="center"
          padding="8rem 0"
          paddingMd="6rem 0"
          gap="2rem"
        >
          <Div margin="0 0 1rem">
            <FlexContainer
              margin="0 auto 1rem"
              justifyContent="center"
              gap="2rem"
              flexFlow="row"
            >
              <Anchor hover="white" link="/powermetrics">
                <Image
                  style={{ margin: "0 auto" }}
                  height={100}
                  width={100}
                  file={data.pmLogo}
                />
                <Paragraph
                  margin="-0.4rem 0 0"
                  fontWeight={700}
                  color="white"
                  fontSize="1.4rem"
                >
                  PowerMetrics
                </Paragraph>
              </Anchor>
              <Anchor hover="white" link="/klips">
                <Image
                  style={{ margin: "0 auto" }}
                  height={100}
                  width={100}
                  file={data.klipsLogo}
                />
                <Paragraph
                  margin="-0.4rem 0 0"
                  fontWeight={700}
                  color="white"
                  fontSize="1.4rem"
                >
                  Klips
                </Paragraph>
              </Anchor>
            </FlexContainer>
            <Heading
              style={{
                fontSize: "clamp(1.5rem, 3vw + 1.5rem, 4rem)",
              }}
              color="white"
              margin={"0 0 1rem"}
              as="h2"
            >
              Succeed with data
            </Heading>
            <Paragraph color="white" fontSize="1.8rem" lineHeight="130%">
              We’ve got you covered.
            </Paragraph>
          </Div>
          <Button.Agnostic
            boxShadow={
              "0px 8px 16px rgba(75, 87, 197, 0.2), inset 0px -6px 14px rgba(255, 255, 255, 0.4)"
            }
            color={color.indigo600}
            background={themeGradient.powerMetricsButtonAlt}
            data-cta-location="eop"
            text
            noticeText="No credit card required"
            noticeColor="white"
          />
        </Flex>
      </Container>
    </Div>
  )
}
